import { useState, useEffect, useCallback, FC } from "react";

import { Button, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

import { Content } from "../../../components/Content";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";

import { IEquipment, IMeta } from "../../../types";
import { formatters } from "../../../utils";
import { useParams } from "react-router-dom";
import { EquipmentModal } from "../../equipments/components/EquipmentModal";
import { getEquipments } from "../../../services/repositories";
import { api } from "../../../services/api";

const ClientEquipments: FC = () => {
  const { id } = useParams();

  const [equipments, setEquipments] = useState<IEquipment[]>([]);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [filter] = useState();
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const [equipment, setItem] = useState<IEquipment>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getEquipments({ page, pageSize, id })
        .then(({ data, meta }) => {
          setEquipments(data);
          setMeta(meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id]
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, filter, meta.per_page, handleChangePage]);

  const actions: ActionFunction<IEquipment> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "2",
        icon: <PrinterOutlined />,
        label: "Imprimir etiqueta",
        onClick: () => onPrint(record.id),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        // onClick: () => onDeleteItem(record),
      },
    ];
  };

  const onPrint = (record?: string) => {
    if (record) {
      const getTicket = async () => {
        try {
          const response = await api.get(`/equipments/ticket/${record}`, {
            responseType: "blob",
          });

          const fileUrl = URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          setPdfUrl(fileUrl);
          setIsPdfModalOpen(true);
        } catch (error) {
          console.error(error);
        }
      };
      getTicket();
    }
  };

  const closePdfModal = () => {
    setIsPdfModalOpen(false);
    setPdfUrl(null);
  };

  const columns: ColumnsType<IEquipment> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 300,
    },

    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      render: ({ name }) => name,
    },
    {
      title: "Tipo",
      dataIndex: "equipmentType",
      key: "equipmentType",
      render: (type) => type?.name,
    },
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      width: 250,
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatters.simpleDate(text),
    },
    ActionColumn(actions),
  ];

  const handleOpenModal = (record?: IEquipment) => {
    if (record) {
      setItem(record);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = (refresh?: boolean) => {
    if (refresh) {
      setLoad(!load);
    }
    setIsModalOpen(false);
    setItem(undefined);
  };

  return (
    <Content>
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Equipamentos do Cliente"}
        actions={[
          <Button
            key={"add_client_equipment"}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleOpenModal()}
          >
            Adicionar Equipamento
          </Button>,
        ]}
      />
      <EquipmentModal
        onClose={handleCloseModal}
        isModalOpen={isModalOpen}
        data={equipment}
      />
      <Modal
        open={isPdfModalOpen}
        title="Visualizar Etiqueta"
        onCancel={closePdfModal}
        footer={null}
        width={300}
      >
        {pdfUrl ? (
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            style={{ width: "100%", height: "265px", border: "none" }}
          />
        ) : (
          <p>Carregando PDF...</p>
        )}
      </Modal>
      <Table
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 30, 50],
          current: meta.current_page,
          pageSize: meta.per_page,
          total: meta.total,
          onChange: handleChangePage,
        }}
        size={"small"}
        loading={loading}
        rowKey={(record: IEquipment) => record.id}
        columns={columns}
        dataSource={equipments}
      />
    </Content>
  );
};

export { ClientEquipments };

import { useSearchParams } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { Col, DatePicker, Form } from "antd";

import { IClient, IContract, ILocal } from "../../../../types";
import { Select } from "../../../../components/Select";
import { ExpandableFilter } from "../../../../components/ExpandableFilter";
import {
  getClients,
  getContractLocals,
  getContracts,
} from "../../../../services/repositories";
import { getAllContracts } from "../../../contracts/list";

const { RangePicker } = DatePicker;

interface FilterProps {
  onSubmit: (values?: any) => void;
  filter?: any;
}

const Filter: FC<FilterProps> = ({ onSubmit, filter }) => {
  const [form] = Form.useForm();

  const [, setSearchParams] = useSearchParams();
  const [clients, setClients] = useState<IClient[]>([]);
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [locals, setLocals] = useState<ILocal[]>([]);

  useEffect(() => {
    if (filter) {
      form.setFieldsValue(filter);
    }
  }, [form, filter]);

  useEffect(() => {
    if (clients.length === 0) {
      getClients(1, 1000).then(({ data }) => setClients(data));
    }
    if (filter?.clientId) {
      getAllContracts(1, 1000, { clientId: filter.clientId }).then(
        ({ data }) => {
          setContracts(data.data);
        }
      );
    }
  }, [filter, clients.length]);

  const onFinish = (values: any) => {
    let params: { [k: string]: any } = {};
    Object.entries(values).forEach(([key, value]) => {
      if (key === "date" && Array.isArray(value)) {
        const [startDate, endDate] = value.map((date) =>
          date?.toISOString().slice(0, 10)
        );
        if (startDate) params["startDate"] = startDate;
        if (endDate) params["endDate"] = endDate;
      } else if (value !== null) {
        params[key] = value;
      }
    });

    setSearchParams(params);
    onSubmit(params);
  };

  const onClear = () => {
    setSearchParams();
    onSubmit();
  };

  const loadContracts = async () => {
    await getContracts(
      {
        page: 1,
        perPage: 1000,
      },
      {
        clientId: form.getFieldValue("clientId"),
      }
    ).then(({ data }) => {
      setContracts(data);
    });
  };

  const loadLocals = async (contractId: string | null) => {
    if (contractId) {
      await getContractLocals({
        id: contractId,
        page: 1,
        perPage: 1000,
      }).then(({ data }) => {
        setLocals(data);
      });
    }
  };

  const fields = [
    <Col span={10} key={1}>
      <Form.Item
        name="clientId"
        label="Cliente"
        rules={[
          {
            required: true,
            message: "Selecione um cliente",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={clients}
          onChange={() => form.setFieldValue("id", null)}
          placeholder="Selecione um cliente"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={2}>
      <Form.Item
        label="Contrato"
        name="contractId"
        rules={[
          {
            required: true,
            message: "Selecione um contrato",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={contracts}
          handleLoadData={loadContracts}
          onChange={loadLocals}
          placeholder="Selecione um contrato"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={3}>
      <Form.Item label="Local" name="localId">
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={locals}
          placeholder="Selecione um local"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={4}>
      <Form.Item
        label="Período"
        name="date"
        rules={[
          {
            required: true,
            message: "Por favor selecione um Período!",
          },
        ]}
      >
        <RangePicker format="DD/MM/YYYY" style={{ width: "100%" }} allowClear />
      </Form.Item>
    </Col>,
  ];

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { Filter };

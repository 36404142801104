import { RouteObject } from "react-router-dom";

import { Worksheet } from "./worksheet";

import { loader } from "../../utils/loader";
import { DetailedItem } from "./detailedItems";
import { GroupedItems } from "./groupedItems";
import { OrdersStatus } from "./ordersStatus";
import { PmocReport } from "./pmocs";
import { CommissionReport } from "./commissionReports";
import { ContractBalance } from "./contracts/contractBalance";
import { ItemBalance } from "./contracts/itemBalance";
import {
  ContractExpiration,
  crumbContractExpiration,
} from "./contracts/contractExpiration ";
import { ServiceBilling } from "./financial/serviceBilling";
import { ContractBilling } from "./financial/contractBilling";
import { EquipmentList } from "./equipment/equipmentList";
import { ServiceHistory } from "./equipment/serviceHistory";

export const ReportsRouter: RouteObject = {
  path: "/reports",
  handle: { crumb: () => "Relatórios" },
  children: [
    {
      path: "worksheet",
      loader: loader,
      handle: {
        crumb: () => "Ordens de Serviço por Status",
      },
      element: <Worksheet />,
    },
    {
      path: "financial-reports",
      children: [
        {
          path: "service-billing",
          loader: loader,
          handle: {
            crumb: () => "Relatório de Faturamento de Serviços por Local",
          },
          element: <ServiceBilling />,
        },
        {
          path: "contract-billing",
          loader: loader,
          handle: {
            crumb: () => "Relatório de Faturamento de Serviços por Local",
          },
          element: <ContractBilling />,
        },
      ],
    },
    {
      path: "equipments",
      children: [
        {
          path: "equipment-list",
          loader: loader,
          handle: {
            crumb: () => "Listagem de Equipamentos",
          },
          element: <EquipmentList />,
        },
        {
          path: "service-history",
          loader: loader,
          handle: {
            crumb: () => "Relatório de Histórico de serviços",
          },
          element: <ServiceHistory />,
        },
      ],
    },
    {
      path: "contract-reports",
      children: [
        {
          path: "item-balance",
          loader: loader,
          handle: {
            crumb: () => "Relatório de Saldo de Itens por Contrato",
          },
          element: <ItemBalance />,
        },
        {
          path: "contract-balance",
          loader: loader,
          handle: {
            crumb: () => "Relatório de Saldo de Contrato",
          },
          element: <ContractBalance />,
        },
        {
          path: "contract-expiration",
          loader: loader,
          handle: {
            crumb: () => crumbContractExpiration,
          },
          element: <ContractExpiration />,
        },
      ],
    },
    {
      path: "services",
      handle: { crumb: () => "Serviços" },
      children: [
        {
          path: "orders-status",
          loader: loader,
          handle: {
            crumb: () => "Ordens de Serviço por Status",
          },
          element: <OrdersStatus />,
        },
        {
          path: "detailed-items",
          loader: loader,
          handle: {
            crumb: () => "Itens de Serviços Detalhados",
          },
          element: <DetailedItem />,
        },
        {
          path: "grouped-items",
          loader: loader,
          handle: {
            crumb: () => "Itens de Serviços Agrupados",
          },
          element: <GroupedItems />,
        },
      ],
    },
    {
      path: "commission",
      handle: { crumb: () => "Comissão" },
      children: [
        {
          path: "simplified-commission",
          loader: loader,
          handle: {
            crumb: () => "Comissão Líquida Simplificada",
          },
          element: <CommissionReport />,
        },
      ],
    },
    {
      path: "pmocs",
      loader: loader,
      handle: {
        crumb: () => "Relatório de PMOC",
      },
      element: <PmocReport />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};

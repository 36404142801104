import { FC, useState } from "react";

import { Button, Modal } from "antd";
import { ColumnsType } from "antd/es/table";

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

import { Content } from "../../../components/Content";
import { ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { FilterItemList } from "../components/FilterItemList";
import { EquipmentModal } from "../components/EquipmentModal";

import { IEquipment } from "../../../types";
import { formatters } from "../../../utils";
import { ActionTable } from "../../../components/ActionTable";
import { useDelete, useModal, usePagination } from "../../../hooks";
import { getEquipments } from "../../../services/repositories";
import { api } from "../../../services/api";

const Equipments: FC = () => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);

  const actions: ActionFunction<IEquipment> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "2",
        icon: <PrinterOutlined />,
        label: "Imprimir etiqueta",
        onClick: () => onPrint(record.id),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () =>
          handleDeleteData(
            `/equipments/${record.id}`,
            "Deseja deletar a equipamento?"
          ),
      },
    ];
  };

  const onPrint = (record?: string) => {
    if (record) {
      const getTicket = async () => {
        try {
          const response = await api.get(`/equipments/ticket/${record}`, {
            responseType: "blob",
          });

          const fileUrl = URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          setPdfUrl(fileUrl);
          setIsPdfModalOpen(true);
        } catch (error) {
          console.error(error);
        }
      };
      getTicket();
    }
  };

  const closePdfModal = () => {
    setIsPdfModalOpen(false);
    setPdfUrl(null);
  };

  const {
    data: equipments,
    isLoading,
    setFilter,
    handleRefreshData,
    pagination,
  } = usePagination(getEquipments);

  const {
    data: equipment,
    isOpen,
    handleCloseModal,
    handleOpenModal,
  } = useModal<IEquipment>(handleRefreshData);

  const { handleDeleteData } = useDelete(handleRefreshData);

  const columns: ColumnsType<IEquipment> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      width: 300,
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      render: ({ name }) => name,
      ellipsis: true,
    },
    {
      title: "Local",
      dataIndex: "local",
      key: "local",
      render: (local) => local?.name,
    },
    {
      title: "Unidade",
      dataIndex: "unit",
      key: "unit",
      render: (unit) => unit?.name,
    },
    {
      title: "Setor",
      dataIndex: "sector",
      key: "sector",
      render: (sector) => sector?.name,
    },
    {
      title: "Tipo",
      dataIndex: "equipmentType",
      key: "equipmentType",
      render: (type) => type?.name,
    },
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      width: 250,
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatters.simpleDate(text),
    },
  ];

  return (
    <>
      <PageHeader
        title="Equipamentos"
        backButton={false}
        actions={[
          <Button
            key={"create_item"}
            onClick={() => handleOpenModal()}
            icon={<PlusOutlined />}
            type="primary"
          >
            Adicionar Equipamento
          </Button>,
        ]}
        crumb
      />
      <EquipmentModal
        onClose={handleCloseModal}
        isModalOpen={isOpen}
        data={equipment}
      />
      <Content width="calc(100% - 48px)" margin>
        <FilterItemList onSubmit={setFilter} />
      </Content>
      <Modal
        open={isPdfModalOpen}
        title="Visualizar Etiqueta"
        onCancel={closePdfModal}
        footer={null}
        width={300}
      >
        {pdfUrl ? (
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            style={{ width: "100%", height: "265px", border: "none" }}
          />
        ) : (
          <p>Carregando PDF...</p>
        )}
      </Modal>
      <Content width="calc(100% - 48px)" margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          loading={isLoading}
          columns={columns}
          dataSource={equipments}
        />
      </Content>
    </>
  );
};

export { Equipments };

import { FC, useEffect, useState } from "react";
import { Col, Form } from "antd";

import { useSearchParams } from "react-router-dom";

import { IClient, IContract } from "../../../../types";
import { getAllContracts } from "../../../contracts/list";
import { Select } from "../../../../components/Select";

import { ExpandableFilter } from "../../../../components/ExpandableFilter";
import { getClients, getContracts } from "../../../../services/repositories";

interface FilterProps {
  onSubmit: (values?: any) => void;
  filter?: any;
}

const Filter: FC<FilterProps> = ({ onSubmit, filter }) => {
  const [form] = Form.useForm();

  const [, setSearchParams] = useSearchParams();
  const [clients, setClients] = useState<IClient[]>([]);
  const [contracts, setContracts] = useState<IContract[]>([]);

  useEffect(() => {
    if (filter) {
      form.setFieldsValue(filter);
    }
  }, [form, filter]);

  useEffect(() => {
    if (clients.length === 0) {
      getClients(1, 1000).then(({ data }) => setClients(data));
    }
    if (filter?.clientId) {
      getAllContracts(1, 1000, { clientId: filter.clientId }).then(
        ({ data }) => {
          setContracts(data.data);
        }
      );
    }
  }, [filter, clients.length]);

  const onFinish = (values: any) => {
    let params: { [k: string]: any } = {};
    Object.entries(values).forEach((value) => {
      if (value[1] !== null) {
        params[value[0]] = value[1];
      }
    });
    setSearchParams(params);
    onSubmit(params);
  };

  const onClear = () => {
    setSearchParams();
    onSubmit();
  };

  const loadContracts = async () => {
    await getContracts(
      {
        page: 1,
        perPage: 1000,
      },
      {
        clientId: form.getFieldValue("clientId"),
      }
    ).then(({ data }) => {
      setContracts(data);
    });
  };

  const fields = [
    <Col span={6} key={1}>
      <Form.Item
        name={"clientId"}
        label={"Cliente"}
        rules={[
          {
            required: true,
            message: "Selecione ao menos um cliente",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={clients}
          onChange={() => form.setFieldValue("id", null)}
          placeholder="Selecione um cliente"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={2}>
      <Form.Item label="Contrato" name="id">
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={contracts}
          handleLoadData={loadContracts}
          placeholder="Selecione um contrato"
        />
      </Form.Item>
    </Col>,
  ];

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { Filter };

import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Form } from "antd";

import { IClient } from "../../../../types";
import { Select } from "../../../../components/Select";
import { ExpandableFilter } from "../../../../components/ExpandableFilter";
import { getClients } from "../../../../services/repositories";

interface FilterProps {
  onSubmit: (values?: any) => void;
  filter?: any;
}

const Filter: FC<FilterProps> = ({ onSubmit, filter }) => {
  const [form] = Form.useForm();

  const [, setSearchParams] = useSearchParams();
  const [clients, setClients] = useState<IClient[]>([]);

  useEffect(() => {
    if (filter) {
      form.setFieldsValue(filter);
    }
  }, [form, filter]);

  useEffect(() => {
    if (clients.length === 0) {
      getClients(1, 1000).then(({ data }) => setClients(data));
    }
  }, [filter, clients.length]);

  const onFinish = (values: any) => {
    let params: { [k: string]: any } = {};
    Object.entries(values).forEach((value) => {
      if (value[1] !== null) {
        params[value[0]] = value[1];
      }
    });
    setSearchParams(params);
    onSubmit(params);
  };

  const onClear = () => {
    setSearchParams();
    onSubmit();
  };

  const fields = [
    <Col span={10} key={1}>
      <Form.Item
        name="clientId"
        label="Cliente"
        rules={[
          {
            required: true,
            message: "Selecione um cliente",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={clients}
          onChange={() => form.setFieldValue("id", null)}
          placeholder="Selecione um cliente"
        />
      </Form.Item>
    </Col>,
  ];

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { Filter };

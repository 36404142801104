import { FC } from "react";
import { App, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { IClient, IEquipment } from "../../../../types";

import { getEquipmentList } from "../../../../services/repositories";
import { reports } from "../../../../services/reports";
import { usePagination } from "../../../../hooks";
import { Content } from "../../../../components/Content";
import { ActionTable } from "../../../../components/ActionTable";
import { ActionFunction } from "../../../../components/ActionColumn";
import { PageHeader } from "../../../../components/PageHeader";
import { Filter } from "./Filter";

const EquipmentList: FC = () => {
  const { message } = App.useApp();
  const {
    data: equipmentList,
    isLoading,
    pagination,
    setFilter,
    filter,
  } = usePagination(getEquipmentList);

  const columns: ColumnsType<IClient> = [
    {
      title: "ID",
      dataIndex: "client",
      key: "client",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value.id.match(/\d/g).slice(0, 4).join("")}
          </div>
        );
      },
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      ellipsis: true,
      render: (client) => client.name,
    },
    {
      title: "Equipamentos",
      dataIndex: "client",
      key: "client",
      render: (client) => {
        const totalEquipments = client?.locals?.reduce(
          (total: number, local: any) => {
            const unitEquipments = local.units?.reduce(
              (unitTotal: number, unit: any) => {
                return unitTotal + (unit.equipments?.length || 0);
              },
              0
            );
            return total + (unitEquipments || 0);
          },
          0
        );
        return totalEquipments || 0;
      },
      ellipsis: true,
    },
  ];

  const actions: ActionFunction<IEquipment> = (record) => {
    return [
      {
        key: "1",
        icon: <PrinterOutlined />,
        label: "Imprimir Registro",
        onClick: () => onPrint(record.clientId),
      },
    ];
  };

  const onPrint = (record?: string) => {
    if (record) {
      reports("equipment-list", { clientId: record });
      return;
    }
    if (filter && equipmentList.length > 0) {
      reports("equipment-list", filter);
      return;
    }
    if (filter && equipmentList.length === 0) {
      message.info("Não há registros para impressão.");
      return;
    }
    message.error("Você precisa filtar as informações!");
  };

  return (
    <>
      <PageHeader
        title="Relatório de Listagem de Equipamentos"
        backButton={false}
        actions={[
          <Button
            onClick={() => onPrint()}
            key={"print_table"}
            icon={<PrinterOutlined />}
            type="primary"
          >
            Imprimir
          </Button>,
        ]}
        crumb
      />
      <Content margin>
        <Filter onSubmit={(values) => setFilter(values)} filter={filter} />
      </Content>
      <Content margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          dataSource={equipmentList}
          loading={isLoading}
          columns={columns}
        />
      </Content>
    </>
  );
};

export { EquipmentList };

import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  DollarCircleOutlined,
  EditOutlined,
  FileSearchOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { getOrders } from "../../../services/repositories";

import { IServiceOrder } from "../../../types";
import { reports } from "../../../services/reports";
import { formatters } from "../../../utils";

import { PageHeader } from "../../../components/PageHeader";
import { Content } from "../../../components/Content";
import { ActionFunction } from "../../../components/ActionColumn";

import { OrderModal } from "../components/CreateOrderModal";
import { OrderType } from "../components/OrderType";
import { ServiceOrderStatusTag } from "../components/ServiceOrderStatusTag";
import { FilterOrderList } from "../components/FilterOrderList";
import { ActionTable } from "../../../components/ActionTable";
import { useDelete, useModal, usePagination } from "../../../hooks";

const { Link } = Typography;

const Orders: FC = () => {
  const navigate = useNavigate();

  const {
    data: orders,
    isLoading,
    filter,
    setFilter,
    handleRefreshData,
    pagination,
  } = usePagination(getOrders);

  const {
    data: order,
    isOpen,
    handleCloseModal,
    handleOpenModal,
  } = useModal<IServiceOrder>(handleRefreshData);

  const { handleDeleteData } = useDelete(handleRefreshData);

  const actions: ActionFunction<IServiceOrder> = (record) => {
    return [
      {
        key: "1",
        icon: <SearchOutlined />,
        label: "Detalhar",
        onClick: () => navigate(`${record.id}/services`),
      },
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Editar",
        disabled: record?.status !== "REQUESTED" && record?.status !== "OPEN",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "3",
        icon: <FileSearchOutlined />,
        label: "Prévia da O.S",
        onClick: () => reports("photographic", { id: record.id }),
      },
      {
        key: "4",
        icon: <FileSearchOutlined />,
        label: "Espelho da O.S",
        onClick: () => reports("order-mirror", { id: record.id }),
      },
      {
        key: "5",
        icon: <DollarCircleOutlined />,
        label: "Comissão da O.S",
        onClick: () => reports("simplified-commission", { id: record.id }),
      },
      {
        key: "6",
        label: "Deletar",
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () =>
          handleDeleteData(
            `/service-orders/${record.id}`,
            "Deseja deletar a Ordem de Serviço?"
          ),
      },
    ];
  };

  const columns: ColumnsType<IServiceOrder> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value, record) => (
        <Link onClick={() => navigate(`${record.id}/services`)}>
          {value?.toString().padStart(4, "0")}
        </Link>
      ),
      className: "link",
    },
    {
      title: "Descrição da OS",
      dataIndex: "description",
      key: "description",
      width: 200,
      render: (value) => value,
      ellipsis: true,
    },
    {
      title: "Tipo de atendimento",
      dataIndex: "type",
      key: "type",
      width: 170,
      render: (type) => <OrderType type={type} />,
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      render: (text) => text?.name || "--",
      ellipsis: true,
    },
    {
      title: "Local",
      dataIndex: "local",
      key: "local",
      render: (local) => local?.name,
      ellipsis: true,
    },
    {
      title: "Técnico",
      dataIndex: "users",
      key: "users",
      render: (users: IServiceOrder["users"]) => {
        return users.find((user) => user.profile === "TECHNICIAN")?.name;
      },
      ellipsis: true,
    },
    {
      title: "Prév. de atendimento",
      dataIndex: "startTo",
      key: "startTo",
      render: (text) => formatters.simpleDate(text),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (status) => <ServiceOrderStatusTag status={status} />,
    },
  ];

  return (
    <>
      <PageHeader
        title="Ordem de Serviço"
        backButton={false}
        actions={[
          <Button
            key={"create_item"}
            onClick={() => handleOpenModal()}
            icon={<PlusOutlined />}
            type="primary"
          >
            Adicionar OS
          </Button>,
        ]}
        crumb
      />
      <OrderModal
        isModalOpen={isOpen}
        onClose={handleCloseModal}
        data={order}
      />
      <Content margin>
        <FilterOrderList onSubmit={setFilter} filter={filter} />
      </Content>
      <Content margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          dataSource={orders}
          loading={isLoading}
          columns={columns}
        />
      </Content>
    </>
  );
};

export { Orders };

import { FC, useEffect, useState } from "react";

import { App, Button, Form, Input, Modal, Select as ASelect, Spin } from "antd";

import { api } from "../../../services/api";
import { IUser, ModalProps } from "../../../types";

interface UserModalProps extends ModalProps<IUser> {
  clientId?: string;
  handleRefreshData: () => void;
}

const clientOptions = [
  { value: "CUSTOMER", label: "USUÁRIO" },
  { value: "MANAGER", label: "GERENTE" },
];

const UserUpdateModal: FC<UserModalProps> = ({
  onClose,
  handleRefreshData,
  isModalOpen,
  data: user,
}) => {
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({ ...user, email: user.account?.email });
    }
  }, [form, user]);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    onClose();
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        const { confirmPassword, ...filteredValues } = values;
        return filteredValues;
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    handleSubmitForm(values)
      .then(() => {
        message.success(`Usuário atualizado com sucesso!`);
        handleCloseModal();
        handleRefreshData();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: IUser) => {
    if (user) {
      return await api.put<IUser>(`/users/${user.id}`, values, {
        params: {
          id: user.id,
        },
      });
    }
  };

  return (
    <Modal
      title={`Editar usuário`}
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" onClick={handleCloseModal} danger disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {user ? "Salvar" : "Adicionar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="user_update_form_modal">
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome do Usuário!",
              },
            ]}
          >
            <Input placeholder="Insira o nome" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Por favor insira o email do Usuário!",
              },
              {
                type: "email",
                message: "Por favor insira o email valido",
              },
            ]}
          >
            <Input placeholder="Insira o email" />
          </Form.Item>
          <Form.Item
            label="Usuário"
            name="username"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome do Usuário!",
              },
              {
                validator: (_, value) => {
                  if (value && value !== value.toLowerCase()) {
                    return Promise.reject(
                      "O nome do usuário deve estar em letras minúsculas!"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="Insira o nome do usuário" />
          </Form.Item>
          <Form.Item
            label="Perfil de Acesso"
            name="profile"
            rules={[
              {
                required: true,
                message: "Por favor selecione o perfil de acesso!",
              },
            ]}
          >
            <ASelect
              optionFilterProp="children"
              showSearch
              allowClear
              options={clientOptions}
              placeholder="Selecione o perfil de acesso"
            />
          </Form.Item>
          <Form.Item
            label="Senha"
            name="password"
            rules={[
              {
                min: 8,
                message: "A senha deve conter no mínimo 8 caracteres",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirmar Senha"
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("As senhas não coincidem!"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { UserUpdateModal };
